<template>
  <div class="">
    <!-- Content Header (Page header) -->
    <div class="">
      <custom-alert v-if="displayAlert==true" :message="alertMessage"
                    v-on:showedAlert="resetAlert"></custom-alert>
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section
              class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3"
            >
              <div class="card">
                <div class="card-body table-responsive">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-3">Manage Group Types</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                            <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                            </ol>
                    </div>
                    </div>                   
                  </div>
                  <div class="">
                        <div class="row">
                          
                        <div class="col-lg-8 mr-0 pr-0">
                          <div class="mt-3">
                            <b-form inline>
                            <label class="mr-sm-2 font-weight-normal">Show</label>
                            <b-form-select
                              id="inline-form-custom-select-pref"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              v-model="perPage"
                              :options="viewEntryOptions"
                              size="sm"
                            ></b-form-select
                            >entries
                          </b-form>

                          </div>
                        
                        </div>

                        <div class="col-lg-4  text-right pr-0">
                          <div class=" card-text d-flex align-items-center float-right"> 
                          <div class="mt-3"> 
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filter"
                              type="search"
                              placeholder="Search"
                            ></b-form-input>
                          </b-input-group>
                          </div>
                          <div class="mt-3 ml-3">
                              <b-button
                              variant="outline-primary"
                              @click="addRow($event.target)"
                              >Add Group Types</b-button
                            >
                          </div>
                          </div>
                        </div>

                        </div>
                  </div>
                


                  <b-table
                    ref="merchandiseTable"
                    id="merchandise-table"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="items"
                    :fields="fields"
                    :busy="!loaded"
                    class="mt-3"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    hover
                    show-empty
                  >
                    <template #cell(actions)="row">
                      <div class="text-left py-0 align-middle">
                        <div class="btn-group btn-group-sm">
                          <button
                            class="btn btn-primary"
                            @click="viewRow(row.item, row.index, $event.target)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            class="btn btn-primary ml-1"
                            @click="editRow(row.item, $event.target)"
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </button>
                          <button
                            class="btn btn-danger ml-1"
                            @click="deleteRow(row.item.id)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template #empty="scope">
                      <h4>{{ scope.emptyText }}</h4>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                  <br />
                  <b-pagination
                    v-if="loaded"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    prev-text="Previous"
                    next-text="Next"
                    aria-controls="merchandise-table"
                    class="float-right custom-color"
                    hide-goto-end-buttons
                  ></b-pagination>
                  <!-- Info modal -->
                  <b-modal
                    :id="infoModal.id"
                    :title="infoModal.title"
                    size="lg"
                    ok-only
                    @hide="resetInfoModal"
                  >
                    <dl class="row">
                      <dt class="col-sm-2">Name</dt>
                      <dd class="col-sm-10">{{ infoModal.content.name }}</dd>

                      <dt class="col-sm-2">Description</dt>
                      <dd class="col-sm-10">{{ infoModal.content.description }}</dd>

                      <dt class="col-sm-6">Minimum Age</dt>
                      <dd class="col-sm-6">{{ infoModal.content.group_text }}</dd>
                      <dt class="col-sm-6">Sport Discipline</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.sport_discipline }}
                      </dd>
                      <dt class="col-sm-6">Current Safe Sport Training Required</dt>
                      <dd class="col-sm-6">{{ infoModal.content.safe_sport }}</dd>

                      <dt class="col-sm-6">Current Background Screening Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.screening }}
                      </dd>

                      <dt class="col-sm-6">Background Screening Payment</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.payment }}
                      </dd>

                      <dt class="col-sm-6">USA-WSWS
                        Membership
                        Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.membership_text }}
                      </dd>

                      <dt class="col-sm-6">Signed Code of Conduct/Conflict of Interest Disclosure Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.signed_code_of_conduct }}
                      </dd>
                      <dt class="col-sm-6">Boardspan Training Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.boardspan }}
                      </dd>
                      <dt class="col-sm-6">Signed Team Documents Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.signed_team_documents }}
                      </dd>
                      <dt class="col-sm-6">List on Web Site</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.list_on_website }}
                      </dd>
                    </dl>
                  </b-modal>
                  <!-- Edit modal -->
                  <b-modal
                    id="edit-modal"
                    :title="editModal.title"
                    size="lg"
                    ref="modal"
                    @ok="handleOk"
                    @hide="resetEditModal"
                  >
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                    <div class="row">
                      <div class="col-md-6">
                          <div>
                            <label class="pt-4 form-label" for="feedback-name"
                              >Name</label
                            >
                            <b-form-input
                              @keydown="errors.clear('name')"
                              v-model="editModal.content.name"
                              :class="errors.has('name') ? 'is-invalid' : ''"
                              id="feedback-name"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              :state="!errors.has('name')"
                            >
                              {{ errors.get("name") }}</b-form-invalid-feedback
                            >
                          </div>
                          <div class="">
                            <label class="pt-4 form-label" for="feedback-name"
                            >Sport Discipline</label
                            >
                            <b-form-select 
                              class="mb-2 mr-sm-2 mb-sm-0" 
                               id="feedback-sport_discipline" 
                              v-model="editModal.content.sport_discipline">
                              <option value="">Select</option>
                              <option v-for="(discipline, index) in disciplines" :value="index"
                                      :key="'types'+index">{{ discipline }}
                              </option>
                             </b-form-select>
                            <b-form-invalid-feedback
                                :state="!errors.has('sport_discipline')"
                            >
                              {{ errors.get("sport_discipline") }}</b-form-invalid-feedback
                            >
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name"
                            >Current
                              Safe
                              Sport
                              Training
                              Required</label
                            >
                            <b-form-input
                                @keydown="errors.clear('safe_sport')"
                                v-model="editModal.content.safe_sport"
                                :class="errors.has('safe_sport') ? 'is-invalid' : ''"
                                id="feedback-safe_sport"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                :state="!errors.has('safe_sport')"
                            >
                              {{ errors.get("safe_sport") }}</b-form-invalid-feedback
                            >
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name"
                            >Background
                              Screening
                              Payment</label
                            >
                            <b-form-input
                                @keydown="errors.clear('payment')"
                                v-model="editModal.content.payment"
                                :class="errors.has('payment') ? 'is-invalid' : ''"
                                id="feedback-payment"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                :state="!errors.has('payment')"
                            >
                              {{ errors.get("payment") }}</b-form-invalid-feedback
                            >
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name"
                            >Boardspan
                              Training
                              Required</label
                            >
                            <b-form-input
                                @keydown="errors.clear('boardspan')"
                                v-model="editModal.content.boardspan"
                                :class="errors.has('boardspan') ? 'is-invalid' : ''"
                                id="feedback-boardspan"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                :state="!errors.has('boardspan')"
                            >
                              {{ errors.get("boardspan") }}</b-form-invalid-feedback
                            >
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name"
                            >Signed
                              Team
                              Documents
                              Required</label
                            >
                            <b-form-input
                                @keydown="errors.clear('signed_team_documents')"
                                v-model="editModal.content.signed_team_documents"
                                :class="errors.has('signed_team_documents') ? 'is-invalid' : ''"
                                id="feedback-signed_team_documents"
                            ></b-form-input>
                            <b-form-invalid-feedback
                                :state="!errors.has('signed_team_documents')"
                            >
                              {{ errors.get("signed_team_documents") }}</b-form-invalid-feedback
                            >
                          </div>
                      </div>
                      <div class="col-md-6">                        
                        <div>
                          <label class="pt-4 form-label" for="feedback-name"
                          >Minimum Age</label
                          >
                          <b-form-input
                              @keydown="errors.clear('minimum_age')"
                              v-model="editModal.content.minimum_age"
                              :class="errors.has('minimum_age') ? 'is-invalid' : ''"
                              id="feedback-minimum_age"
                          ></b-form-input>
                          <b-form-invalid-feedback
                              :state="!errors.has('minimum_age')"
                          >
                            {{ errors.get("minimum_age") }}</b-form-invalid-feedback
                          >
                        </div>
                        <div>
                          <label class="pt-4 form-label" for="feedback-name"
                          >Current
                            Background
                            Screening
                            Required</label
                          >
                          <b-form-input
                              @keydown="errors.clear('screening')"
                              v-model="editModal.content.screening"
                              :class="errors.has('screening') ? 'is-invalid' : ''"
                              id="feedback-screening"
                          ></b-form-input>
                          <b-form-invalid-feedback
                              :state="!errors.has('screening')"
                          >
                            {{ errors.get("screening") }}</b-form-invalid-feedback
                          >
                        </div>
                        <div>
                          <label
                              class="pt-4 form-label"
                              for="feedback-discount_type"
                          >USA-WSWS
                            Membership
                            Required</label
                          >
                            <b-form-checkbox
                                v-model="editModal.content.membership"
                                name="membership[]"
                                value="1"
                            >Active</b-form-checkbox
                            >
                            <b-form-checkbox
                                v-model="editModal.content.membership"
                                name="membership[]"
                                value="2"
                            >Supporting</b-form-checkbox
                            >
                          <span class="text-sm text-danger">{{
                              errors.get("discount_type")
                            }}</span>
                        </div>
                        <div>
                          <label class="pt-4 form-label" for="feedback-name"
                          >Signed
                            Code of
                            Conduct/Conflict of Interest Disclosure
                            Required</label
                          >
                          <b-form-input
                              @keydown="errors.clear('signed_code_of_conduct')"
                              v-model="editModal.content.signed_code_of_conduct"
                              :class="errors.has('signed_code_of_conduct') ? 'is-invalid' : ''"
                              id="feedback-signed_code_of_conduct"
                          ></b-form-input>
                          <b-form-invalid-feedback
                              :state="!errors.has('signed_code_of_conduct')"
                          >
                            {{ errors.get("signed_code_of_conduct") }}</b-form-invalid-feedback
                          >
                        </div>
                        <div>
                          <label class="pt-4 form-label" for="feedback-name"
                          >List on
                            Web Site</label
                          >
                          <b-form-input
                              @keydown="errors.clear('list_on_website')"
                              v-model="editModal.content.list_on_website"
                              :class="errors.has('list_on_website') ? 'is-invalid' : ''"
                              id="feedback-list_on_website"
                          ></b-form-input>
                          <b-form-invalid-feedback
                              :state="!errors.has('list_on_website')"
                          >
                            {{ errors.get("payment") }}</b-form-invalid-feedback
                          >
                        </div>
                        <div>
                          <label class="pt-4 form-label" for="feedback-code"
                          >Description</label
                          >
                          <b-form-textarea
                              @keydown="errors.clear('description')"
                              v-model="editModal.content.description"
                              :class="errors.has('description') ? 'is-invalid' : ''"
                              id="feedback-description"
                          ></b-form-textarea>
                          <b-form-invalid-feedback
                              :state="!errors.has('description')"
                          >
                            {{ errors.get("description") }}</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                    </div>
                    </form>
                  </b-modal>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
    </div>
    <!-- /.content -->

  </div>
</template>

<script>

import axios from "axios";
import Errors from "../../Errors.js";
import moment from "moment";
import CustomAlert from "../CustomAlert";
/*import DatePick from "vue-date-pick";*/

let cachedData = {};

export default {
  name: "committeeGroup",
  components: { CustomAlert },

  data() {
    return {
      loaded: false,
      alertMessage:'',
      displayAlert:'',
      perPage: 10,
      currentPage: 1,
      disciplines:[],
      errors: new Errors(),
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "sport_discipline_text",
          label: "Sport Discipline",
          sortable: true,
        },
        {
          key: "safe_sport",
          sortable: true,
        },
        {
          key: "screening",
          sortable: true,
        },
        {
          key: "payment",
          sortable: true,
        },
        {
          key: "membership_text",
          label: "Membership",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      items: [],
      viewEntryOptions: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 20, text: "20" },
      ],
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      editModal: {
        editing: true,
        id: "edit-modal",
        title: "",
        content: {},
      },
    };
  },

  filters: {
    productStatus(value) {
      return value ? "Active" : "Inactive";
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
  },

  methods: {
     formatlongDate(datevalue)
        {
            var date = moment(datevalue);
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
        getDiscipline()
      {
        axios.get(this.basePath+"api/getDisciplines/Committee")
            .then(response => {
              this.disciplines = response.data.data;
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addRow(button) {
      (this.editModal.editing = false),
        (this.editModal.title = "Add New Group Types");
      let id = localStorage.getItem("id");
      this.editModal.content = {
        name: "",
        sport_discipline: "",
        membership: [],
        organization_id: 1,
        updated_by: id,
        discount_type: 1,
        status: 1,
      };
      this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    viewRow(item, index, button) {
      this.infoModal.title = "View " +item.name;
      this.infoModal.content = item;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    editRow(item, button) {
      (this.editModal.editing = true),
        (this.editModal.title = "Update " + item.name);
      this.editModal.content = item;
      console.log(item);
      this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    deleteRow(id) {
      this.displayAlert = false;
      this.$bvModal.msgBoxConfirm('Are you sure to delete this item?', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(async value => {
            if(String(value)=="true")
            {
              axios
                  .delete(process.env.VUE_APP_URL + "api/committee_groups/delete/" + id)
                  .then((response) => {
                    this.alertMessage = "Committee Group deleted!";
                    this.displayAlert = true;
                    this.resetEditModal();
                  })
                  .catch((error) => {
                    (error) => (this.errors = error.response.data);
                  });
              return false;
            }
          })
          .catch(err => {
            console.log(err);
          })

    },

    //Modals
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetEditModal() {
      this.errors.clear();
      this.loaded = false;
      axios
        .get(process.env.VUE_APP_URL + "api/committee_groups")
        .then((response) => {
          cachedData = response.data.committee_groups;
          this.items = cachedData;
          this.loaded = true;
        })
        .catch((error) => (this.errors = error.response.data));
      this.loaded = true;
    },

    // Submitting
    onFail(errors) {
      this.errors.record(errors);
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      let url = process.env.VUE_APP_URL + "api/committee_groups";
      let requestType = "post";

      if (this.editModal.editing) {
        url = process.env.VUE_APP_URL + "api/committee_groups/update/" + this.editModal.content.id;
        requestType = "patch";
      }

      this.handleSubmit(requestType, url)
        .then(() => {
          this.$nextTick(() => {
            var message = this.editModal.editing ? "saved" : "created";
            this.alertMessage = "Ccommittee Group has been " + message + ".";
            this.displayAlert = true;
            this.$bvModal.hide("edit-modal");
          });
        })
        .catch(() => false);
    },

    handleSubmit(requestType, url) {
      return new Promise((resolve, reject) => {
        axios[requestType](url, this.editModal.content)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            this.onFail(error.response.data.errors);
            reject(error.response.data);
          });
      });
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
  },

  created() {
    if (Object.keys(cachedData).length === 0) {
      axios
        .get(process.env.VUE_APP_URL + "api/committee_groups")
        .then((response) => {
          cachedData = response.data.committee_groups;
          this.items = cachedData;
          this.loaded = true;
        })
        .catch((error) => (this.errors = error.response.data));
      return false;
    }
    this.items = cachedData;
    this.loaded = true;
  },

  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
      this.getDiscipline();
    },
};
</script>
